import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Timeline } from 'react-twitter-widgets'
import './App.css';

import { MainText, MatchDetails, Item } from './MainText';
import React, { useState } from 'react';

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

function App() {


  const [showMatchInfo, setshowMatchInfo] = useState(false);

  function toggleMatchInfo(e) {
    e.preventDefault();
    setshowMatchInfo(!showMatchInfo);
  }

  function MatchLink() {
    return <Item>
      Click <button onClick={toggleMatchInfo}>here</button> for match details
    </Item>
  }

  function MainLink() {
    return <Item>
      Click <button onClick={toggleMatchInfo}>here</button> for the main page
    </Item>
  }

  if (isMobile()) {
    return (
      <Container>
        <Grid
          container spacing={2}
          justifyContent="center"
          alignItems="bottom"
        >
          <Grid item xs={12}>
            <img src="nettles_logo.png" alt="Nettles Logo" />
          </Grid>
          <Grid item xs={12}>
            {showMatchInfo ? <MainLink /> : <MatchLink />}
          </Grid>
          <Grid item xs={12}>
            {showMatchInfo ? <MatchDetails /> : <MainText />}
          </Grid>

          <Grid item xs={12}>
            <Timeline
              dataSource={{
                sourceType: 'url',
                url: 'https://twitter.com/NettlesClub'
              }}
              options={{
                height: '450'
              }}
            />
          </Grid>
        </Grid>
      </Container >
    );
  }
  return (
    <Container maxWidth="lg">
      <Grid
        container spacing={2}
        justifyContent="center"
      >

        <Grid item xs={9}>
          <img src="nettles_logo.png" alt="Nettles Logo" />
        </Grid>
        <Grid item xs={3}>
          {showMatchInfo ? <MainLink /> : <MatchLink />}
        </Grid>
        <Grid item xs={9}>
          {showMatchInfo ? <MatchDetails /> : <MainText />}
        </Grid>
        <Grid item xs={3}>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'NettlesClub'
            }}
            options={{
              height: '700'
            }}
          />
        </Grid>
      </Grid>
    </Container >
  );
}

export default App;


