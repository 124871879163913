import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function MainText() {
  return <Item>
    <h1>Welcome</h1>
    <p>Thanks for visiting the website of Nettles Badminton and Social Club. We are a friendly,
      mixed ability club that has been running since 1986; we typically have around 30 members.
      We try to maintain an even number of male and female club members meaning that like most
      clubs, we are always keen to recruit more female players.</p>

    <h2>Club night</h2>
    <p>Our club night is on Tuesday from 7.00 pm - 10.00 pm at&nbsp;
      <a href="https://maps.google.com/?q=Manchester+enterprise+academy">Manchester Enterprise
        Academy</a> We have the use of 4 courts and we play with feather shuttles. We enter a team in
      the <a href="http://www.manchesterbadmintonleague.org.uk/">
        Manchester Badminton League</a> - with matches played during the autumn and winter.
      Sometimes we need to use a different venue so have a look at our Twitter feed to check
      if this is the case.</p>

    <h2>Visitors</h2>
    <p>We're always looking for new members but we need to manage the number of visitors to
      ensure everyone gets some games. Please contact us before coming down -&nbsp;
      <a href="mailto:contact@nettlesbadminton.org.uk?subject=Visitor">contact@nettlesbadminton.org.uk</a>.
      Visitors need to pay £6 per visit.
    </p>

    <h2>Social</h2>
    <p>As well as the badminton, we have club social activities and our Social Secretary is
      always open to new suggestions.</p>
  </Item>;
}

function MatchDetails() {
  return <Item>
    <h1>Match Nights</h1>
    <p>Our home matches are played during our club night and start at 7.15pm prompt. The car park is often 
      busy so we suggest parking in the front car park which is to your right as you drive onto the MEA grounds.</p>
    <p>We have one dedicated match court and a second court that is shared with the club night players.
       MEA has changing and shower facilities should you need them. There are no drinking fountains to 
       fill up bottles so we suggest bringing a drink with you.</p>
    <p>Latest match results can be found on the <a href="http://www.manchesterbadmintonleague.org.uk/">
        league</a> website.</p>
  </Item>;
}

export { MainText, Item, MatchDetails };